import { AxiosResponse } from 'axios'
import { Pagination } from '@/domain/entities/Pagination'
import { Utils } from '@/data/infrastructures/misc/Utils'
import { BaseResponseMapper } from './BaseMapper'
import {
  Pickup,
  PickupAccount,
  PickupDetail,
  PickupHistory,
  PickupInfo,
  Pickups,
  NearbyCourier,
  CourierPickupDetail,
  PickupDetailSnapshot,
  Couriers,
  PickupSummary,
  PickupForceDetail,
  PickupAdditionalTimes,
  PickupAdditionalTime,
  PickupCRROTWs,
  PickupCRROTWSummary,
  PickupCRROTW,
  PickupCRRQUEs,
  PickupCRRQUE,
  PickupCRRQUECustomer,
  PickupCRRQUEPOS,
  PickupCRRQUEPickup,
  PickupCRRQUESummary,
  PickupCorporates,
  PickupCorporate,
  PickupCorporateSummary,
} from '@/domain/entities/Pickup'
import {
  PickupDetailSnapshotResponse,
  CouriersResponse,
} from '@/data/persistences/responses/contracts/PickupResponse'
import {
  PickupAccountResponse,
  PickupDetailReponse,
  PickupHistoryResponse,
  PickupResponse,
} from '@/data/responses/contracts/PickupResponse'

export class PickupMapper extends BaseResponseMapper {
  public convertPickupsFromApi(result: AxiosResponse): Pickups {
    const {
      data: { data, pagination },
    } = result
    return new Pickups(
      Utils.toInstance(new Pagination(), pagination),
      data.map(
        (
          pickup: Record<
            string,
            string | number | boolean | Record<string, string | number>
          >
        ) => {
          const account = <Record<string, string | number>>pickup.account
          const pickupInfo = <Record<string, string | number>>pickup.pickup
          const pickupHistory = <Record<string, string | number>>pickup.history
          const courierPickupDetail = <Record<string, string | number>>(
            (pickup.courier_pickup_detail || pickup.courier_detail)
          )

          return new Pickup(
            <string>pickup.shipment_id,
            <string>pickup.group_id,
            <string>pickup.shipment_type,
            new PickupAccount(
              Number(account.account_id),
              <string>account.account_role
            ),
            pickupInfo
              ? new PickupInfo(
                  <string>pickupInfo.fullname,
                  <string>pickupInfo.address,
                  <string>pickupInfo.phone_number,
                  Number(pickupInfo.latitude),
                  Number(pickupInfo.longitude),
                  <string>pickupInfo.note,
                  <string>pickupInfo.country,
                  <string>pickupInfo.province,
                  <string>pickupInfo.district,
                  <string>pickupInfo.sub_district,
                  <string>pickupInfo.village
                )
              : null,
            <boolean>pickup.is_onepack,
            <boolean>pickup.is_dedicated,
            Number(pickup.quantity),
            <string>pickup.created_at,
            <string>pickup.expired_at,
            new PickupHistory(
              Number(pickupHistory.history_id),
              <string>pickupHistory.history_type,
              <string>pickupHistory.status_id,
              <string>pickupHistory.status,
              <string>pickupHistory.location,
              <string>pickupHistory.city,
              Number(pickupHistory.actor_id),
              <string>pickupHistory.actor_role,
              <string>pickupHistory.actor_name,
              <string>pickupHistory.reason_id,
              <string>pickupHistory.reason,
              <string>pickupHistory.relation_id,
              <string>pickupHistory.relation,
              <string>pickupHistory.person_name,
              <string>pickupHistory.note,
              <string>pickupHistory.meta,
              <string>pickupHistory.created_at
            ),
            <string>pickup.flag,
            <string>pickup.do_origin_package_id,
            <string>pickup.booking_id,
            courierPickupDetail
              ? new CourierPickupDetail(
                  Number(courierPickupDetail.courier_id),
                  <string>(
                    (courierPickupDetail.courier_name ||
                      courierPickupDetail.fullname)
                  ),
                  <string>courierPickupDetail.courier_type_valid,
                  <string>courierPickupDetail.origin,
                  <string>courierPickupDetail.partner_name,
                  <string>courierPickupDetail.partner_type,
                  <string>courierPickupDetail.phone_number,
                  <string>courierPickupDetail.vehicle_plate,
                  <string>courierPickupDetail.vehicle_type
                )
              : undefined,
            <string>pickup.product_type,
            <boolean>pickup.is_priority || false,
            <number>pickup.pickup_booster_amount || 0 
          )
        }
      )
    )
  }

  public convertPickupFromApi(result: AxiosResponse): PickupDetail {
    const { data } = result

    const account = <Record<string, string | number>>data.account
    const pickupInfo = <Record<string, string | number>>data.pickup
    const pickupHistories = <Record<string, string | number>[]>data.histories

    return new PickupDetail(
      <string>data.shipment_id,
      <string>data.group_id,
      <string>data.shipment_type,
      new PickupAccount(
        Number(account.account_id),
        <string>account.account_role
      ),
      new PickupInfo(
        <string>pickupInfo.fullname,
        <string>pickupInfo.address,
        <string>pickupInfo.phone_number,
        Number(pickupInfo.latitude),
        Number(pickupInfo.longitude),
        <string>pickupInfo.note,
        <string>pickupInfo.country,
        <string>pickupInfo.province,
        <string>pickupInfo.district,
        <string>pickupInfo.sub_district,
        <string>pickupInfo.village
      ),
      <boolean>data.is_onepack,
      Number(data.quantity),
      <string>data.created_at,
      <string>data.expired_at,
      <string>data.invoice_id,
      <string>data.booking_id,
      pickupHistories.map(
        pickupHistory =>
          new PickupHistory(
            Number(pickupHistory.history_id),
            <string>pickupHistory.history_type,
            <string>pickupHistory.status_id,
            <string>pickupHistory.status,
            <string>pickupHistory.location,
            <string>pickupHistory.city,
            Number(pickupHistory.actor_id),
            <string>pickupHistory.actor_role,
            <string>pickupHistory.actor_name,
            <string>pickupHistory.reason_id,
            <string>pickupHistory.reason,
            <string>pickupHistory.relation_id,
            <string>pickupHistory.relation,
            <string>pickupHistory.person_name,
            <string>pickupHistory.note,
            <string>pickupHistory.meta,
            <string>pickupHistory.created_at
          )
      ),
      <string>data.flag,
      undefined,
      <string[]>data.shipment_group_ids,
      <string[]>data.product_type,
      <number>data.weight,
      <string>data.client_id,
      <string>data.client_name,
      <string>data.vehicle_expectation,
      <string>data.pickup_date_expectation,
      <string>data.pickup_time_expectation,
      <number>data.estimate_koli,
      <number>data.estimasi_tonase,
      <number>data.total_nfd,
      <boolean>data.is_priority || false
    )
  }

  public convertPickupBulkFromApi(result: AxiosResponse): PickupDetail[] {
    const {
      data: { data },
    } = result

    return data.map((item: PickupDetailReponse) => {
      const account: PickupAccountResponse = item.account
      const pickupInfo: PickupResponse = item.pickup
      const pickupHistories: Array<PickupHistoryResponse> = item.histories || []

      return new PickupDetail(
        item.shipment_id,
        item.group_id,
        item.shipment_type,
        new PickupAccount(
          Number(account.account_id),
          String(account.account_role)
        ),
        pickupInfo
          ? new PickupInfo(
              pickupInfo.fullname,
              pickupInfo.address,
              pickupInfo.phone_number,
              Number(pickupInfo.latitude),
              Number(pickupInfo.longitude),
              pickupInfo.note,
              pickupInfo.country,
              pickupInfo.province,
              pickupInfo.district,
              pickupInfo.sub_district,
              pickupInfo.village
            )
          : undefined,
        item.is_onepack as boolean,
        Number(item.quantity),
        String(item.created_at),
        String(item.expired_at),
        item.invoice_id,
        item.booking_id,
        pickupHistories.map(
          pickupHistory =>
            new PickupHistory(
              Number(pickupHistory.history_id),
              pickupHistory.history_type,
              pickupHistory.status_id,
              pickupHistory.status,
              pickupHistory.location,
              pickupHistory.city,
              Number(pickupHistory.actor_id),
              pickupHistory.actor_role,
              pickupHistory.actor_name,
              pickupHistory.reason_id,
              pickupHistory.reason,
              pickupHistory.relation_id,
              pickupHistory.relation,
              pickupHistory.person_name,
              pickupHistory.note,
              pickupHistory.meta,
              String(pickupHistory.created_at),
            )
        ),
        item.flag,
        '',
        item.shipment_group_ids,
        <string[]>item.product_type,
        item.weight,
        <string>item.client_id,
        <string>item.client_name,
        <string>item.vehicle_expectation,
        <string>item.pickup_date_expectation,
        <string>item.pickup_time_expectation,
        <number>item.estimate_koli,
        <number>item.estimasi_tonase,
        <number>item.total_nfd,
        <boolean>item.is_priority || false
      )
    })
  }

  public convertPickupDetailFromApi(result: AxiosResponse): PickupForceDetail {
    const {
      data: { data },
    } = result

    return new PickupForceDetail(
      data.actor_name,
      data.booking_id,
      data.courier_id,
      data.customer_role,
      data.is_dedicated,
      data.receiver_address,
      data.receiver_contact,
      data.receiver_name,
      data.shipment_id,
      data.task_id
    )
  }

  public convertSnapshotFromApi(result: AxiosResponse): PickupDetailSnapshot[] {
    const { data } = result
    return data.map((snap: PickupDetailSnapshotResponse) => {
      return new PickupDetailSnapshot(
        snap.snapshot_id,
        snap.shipment_id,
        snap.couriers.map((courier: CouriersResponse) => {
          return new Couriers(
            courier.courier_id,
            courier.latitude,
            courier.longitude,
            courier.distance,
            courier.created_at
          )
        }),
        snap.created_at
      )
    })
  }

  public convertNearbyCourierFromApi(result: AxiosResponse): NearbyCourier[] {
    const {
      data: { data },
    } = result

    if (!data) {
      return []
    }

    return data.map(
      (courier: Record<string, string | number | Record<string, boolean>>) => {
        const onDuty = courier.onduty as Record<string, boolean>

        return new NearbyCourier(
          Number(courier.courier_id),
          courier.courier_name as string,
          courier.courier_phone as string,
          courier.courier_pos as string,
          onDuty.onduty
        )
      }
    )
  }

  public convertPickupSummaryFromApi(result: AxiosResponse): any {
    const {
      data: { data },
    } = result
    if (!data) {
      return []
    }

    return new PickupSummary(
      data.crrsrc as number,
      data.crrnfd as number,
      data.dox as number,
      data.crrotw as number,
      data.crrpic as number,
      data.crrdon as number,
      data.all as number,
      data.crrque as number
    )
  }

  public convertCRRNFDExportFromApi(result: AxiosResponse): string {
    const {
      data: { url },
    } = result
    return url
  }

  public convertPickupAdditionalTimeFromApi(
    result: AxiosResponse
  ): PickupAdditionalTimes {
    const {
      data: { data, pagination },
    } = result

    return new PickupAdditionalTimes(
      Utils.toInstance(new Pagination(), pagination),
      data.map((item: Record<string, never>) => {
        return new PickupAdditionalTime(
          item.order_id,
          item.additional_time,
          item.courier_id,
          item.courier_name,
          item.start_time_pickup,
          item.end_time_pickup
        )
      })
    )
  }

  public convertPickupListFromApi(result: AxiosResponse): string {
    const {
      data: { url },
    } = result
    return url
  }

  public convertPickupCRROTWsFromApi(result: AxiosResponse): PickupCRROTWs {
    const {
      data: { data, pagination, summary },
    } = result
    return new PickupCRROTWs(
      Utils.toInstance(new Pagination(), pagination),
      data.map(
        (
          pickup: Record<
            string,
            string | number | boolean | Record<string, string | number>
          >
        ) => {
          const account = <Record<string, string | number>>pickup.account
          const pickupInfo = <Record<string, string | number>>pickup.pickup
          const courierPickupDetail = <Record<string, string | number>>(
            (pickup.courier_pickup_detail || pickup.courier_detail)
          )

          return new PickupCRROTW(
            <string>pickup.shipment_id,
            <string>pickup.group_id,
            <string>pickup.shipment_type,
            new PickupAccount(
              Number(account.account_id),
              <string>account.account_role
            ),
            pickupInfo
              ? new PickupInfo(
                  <string>pickupInfo.fullname,
                  <string>pickupInfo.address,
                  <string>pickupInfo.phone_number,
                  Number(pickupInfo.latitude),
                  Number(pickupInfo.longitude),
                  <string>pickupInfo.note,
                  <string>pickupInfo.country,
                  <string>pickupInfo.province,
                  <string>pickupInfo.district,
                  <string>pickupInfo.sub_district,
                  <string>pickupInfo.village
                )
              : null,
            <boolean>pickup.is_onepack,
            <string>pickup.product_type,
            Number(pickup.quantity),
            <string>pickup.created_at,
            <string>pickup.expired_at,
            <string>pickup.crrotw_at,
            <string>pickup.crrsrc_at,
            <string>pickup.last_updated,
            <string>pickup.do_origin_package_id,
            <string>pickup.booking_id,
            <string>pickup.task_id,
            <string>pickup.flag,
            <boolean>pickup.is_dedicated,
            <boolean>pickup.is_contacted,
            <boolean>pickup.can_contact,
            courierPickupDetail
              ? new CourierPickupDetail(
                  Number(courierPickupDetail.courier_id),
                  <string>(
                    (courierPickupDetail.courier_name ||
                      courierPickupDetail.fullname)
                  ),
                  <string>courierPickupDetail.courier_type_valid,
                  <string>courierPickupDetail.origin,
                  <string>courierPickupDetail.partner_name,
                  <string>courierPickupDetail.partner_type,
                  <string>courierPickupDetail.phone_number,
                  <string>courierPickupDetail.vehicle_plate,
                  <string>courierPickupDetail.vehicle_type
                )
              : undefined,
              <boolean>pickup.is_priority || false,
              <number>pickup.pickup_booster_amount || 0 
          )
        }
      ),
      Utils.toInstance(new PickupCRROTWSummary(), summary)
    )
  }

  public convertCRROTWExportFromApi(result: AxiosResponse): string {
    const {
      data: { url },
    } = result
    return url
  }

  public convertCRRQUEFromApi(result: AxiosResponse): PickupCRRQUEs {
    const {
      data: { data, pagination, summary },
    } = result

    return new PickupCRRQUEs(
      data.map(
        (
          pickup: Record<
            string,
            | string
            | number
            | boolean
            | Record<string, string | number | boolean>
          >
        ) => {
          const customer = <Record<string, string | number>>pickup.customer
          const pos = <Record<string, string | number>>pickup.pos
          const pickupData = <Record<string, string | number>>pickup.pickup

          return new PickupCRRQUE(
            <string>pickup.shipment_id,
            <string>pickup.shipment_type,
            new PickupCRRQUECustomer(
              <string>customer.name,
              <string>customer.address,
              <string>customer.phone_number
            ),
            new PickupCRRQUEPOS(
              <string>pos.name,
              <string>pos.phone,
              <number>pos.agent_id,
              <string>pos.agent_code,
              <string>pos.address
            ),
            new PickupCRRQUEPickup(
              <number>pickupData.latitude,
              <number>pickupData.longitude
            ),
            <boolean>pickup.is_contacted,
            <string>pickup.created_at,
            <string>pickup.expired_at,
            <string>pickup.crrque_at,
            <string>pickup.crrsrc_at,
            <string>pickup.flag,
            <boolean>pickup.can_contact
          )
        }
      ),
      Utils.toInstance(new Pagination(), pagination),
      Utils.toInstance(new PickupCRROTWSummary(), summary)
    )
  }

  convertCRRQUEExportFromApi(result: AxiosResponse): string {
    const {
      data: { url },
    } = result

    return url
  }

  convertManualAssignPickupFromApi(result: AxiosResponse): string {
    const {
      data: { data },
    } = result

    return data.url
  }

  convertPickupCorporatesFromApi(result: AxiosResponse): PickupCorporates {
    const {
      data: { data, pagination },
    } = result

    const pickupData = data || []
    const paginationData = JSON.stringify(pagination)

    return new PickupCorporates({
      data: pickupData.map((item: any) => {
        const courierPickupDetail = item.courier_pickup_detail

        return new PickupCorporate({
          shipmentId: item.shipment_id,
          groupId: item.group_id,
          shipmentType: item.shipment_type,
          account: new PickupAccount(
            item.account.account_id,
            item.account.account_role
          ),
          pickup: new PickupInfo(
            item.pickup.fullname,
            item.pickup.address,
            item.pickup.phone_number,
            item.pickup.latitude,
            item.pickup.longitude,
            item.pickup.note,
            item.pickup.country,
            item.pickup.province,
            item.pickup.district,
            item.pickup.sub_district,
            item.pickup.village
          ),
          isOnepack: item.is_onepack,
          productType: item.product_type,
          quantity: item.quantity,
          weight: item.weight,
          createdAt: item.created_at,
          expirateAt: item.expired_at,
          history: new PickupHistory(
            item.history.history_id,
            item.history.history_type,
            item.history.status_id,
            item.history.status,
            item.history.location,
            item.history.city,
            item.history.actor_id,
            item.history.actor_role,
            item.history.actor_name,
            item.history.reason_id,
            item.history.reason,
            item.history.relation_id,
            item.history.relation,
            item.history.person_name,
            item.history.note,
            item.history.meta,
            item.history.created_at,
            item.history.total_nfd || 0
          ),
          flag: item.flag,
          doOriginPackageId: item.do_origin_package_id,
          bookingId: item.booking_id,
          taskId: item.task_id,
          isDedicated: item.is_dedicated,
          clientId: item.client_id,
          clientName: item.client_name,
          totalNfd: item.history.total_nfd || item.total_nfd,
          estimateKoli: Number(item.estimate_koli),
          estimasiTonasi: Number(item.estimasi_tonase),
          vehicleExpectation: item.transport_type || item.vehicle_expectation,
          pickupDateExpectation: item.pickup_date_expectation,
          pickupTimeExpectation: item.pickup_time_expectation,
          couerierPickupDetail: courierPickupDetail
            ? new CourierPickupDetail(
                Number(courierPickupDetail.courier_id),
                <string>(
                  (courierPickupDetail.courier_name ||
                    courierPickupDetail.fullname)
                ),
                <string>courierPickupDetail.courier_type_valid,
                <string>courierPickupDetail.origin,
                <string>courierPickupDetail.partner_name,
                <string>courierPickupDetail.partner_type,
                <string>courierPickupDetail.phone_number,
                <string>courierPickupDetail.vehicle_plate,
                <string>courierPickupDetail.vehicle_type
              )
            : undefined,
        })
      }),
      pagination: Utils.toInstance(new Pagination(), paginationData),
    })
  }

  convertPickupCorporateSummaryFromApi(
    result: AxiosResponse
  ): PickupCorporateSummary {
    const { data } = result

    return new PickupCorporateSummary({
      one: data.one,
      two: data.two,
      three: data.three,
      four: data.four,
      five: data.five,
      moreThanFive: data.more_than_five,
    })
  }
}
